import React, { useRef, useState } from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../../utils/richText.utils'

import Grid from '@material-ui/core/Grid'
import { VerticalTabContainer } from './style'
import Slider from 'react-slick'

import GlobalContainer from '../globalContainer'
import Typography from '../typography'
import Button from '../button'

import Path from '../../../../static/steam-img/Path.svg'
import Robot from '../../../../static/steam-img/orange-robot.png'
import { internalLinkHandler } from '../../../utils/link.utils' 
import formatInlineCode from '../../../utils/shortTextFormatting'
import WrapperImage from '../wrapperImage'

const VerticalTab = (props: any) => {
  if (props.data) {
    const panels = props.data.panels ? props.data.panels : []
    const topPadding = props.data.topPadding ? props.data.topPadding : ''
    const bottomPadding = props.data.bottomPadding
      ? props.data.bottomPadding
      : ''
    const [slideNumber, setSlideNumber] = useState(0)
    const customSlider = useRef<HTMLDivElement>()
    const tabs = useRef<HTMLUListElement>()

    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed: 500,
      // cssEase: 'linear',
      swipe: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // adaptiveHeight: true,
      beforeChange: (current: number, next: number) => {
        if (tabs && tabs.current && tabs.current.children) {
          tabs.current.children[slideNumber] &&
            tabs.current.children[slideNumber].classList.remove(
              'VerticalTabs__Active--Desktop'
            )

          tabs.current.children[slideNumber] &&
            tabs.current.children[next].classList.add(
              'VerticalTabs__Active--Desktop'
            )
        }
        setSlideNumber(next)
      },
    }

    const handleSlideNext = () => {
      if (typeof customSlider !== undefined && customSlider.current) {
        // customSlider.current.slickNext()
        customSlider.current.slickGoTo((slideNumber + 1) % panels.length)
      }
    }

    const goToSlide = (idx: number) => {
      let screenSize = window.matchMedia('(max-width: 1024px)')
      if (
        screenSize.matches &&
        tabs.current &&
        tabs.current.children &&
        tabs.current.children[idx]
      ) {
        // For Accordion Tab
        if (
          tabs.current.children[idx].classList.contains(
            'VerticalTabs__Active--Mobile'
          )
        )
          tabs.current.children[idx].classList.remove(
            'VerticalTabs__Active--Mobile'
          )
        else
          tabs.current.children[idx].classList.add(
            'VerticalTabs__Active--Mobile'
          )
      } else if (typeof customSlider !== undefined && customSlider.current) {
        customSlider.current.slickGoTo(idx)
      }
    }

    const handleSlidePrev = () => {
      if (typeof customSlider !== undefined && customSlider.current) {
        // customSlider.current.slickPrev()
        customSlider.current.slickGoTo(
          (slideNumber - 1 < 0 ? panels.length - 1 : slideNumber - 1) %
            panels.length
        )
      }
    }

    return panels.length ? (
      <VerticalTabContainer
        topPadding={topPadding}
        bottomPadding={bottomPadding}
        data-cy="VerticalTab"
      >
        <GlobalContainer>
          <Grid className="VerticalTabs__Grid-Container" container spacing={3}>
            <Grid className="VerticalTabs__Menu" item xs={12} sm={12} md={4}>
              <ul ref={(tab: any) => (tabs.current = tab)}>
                {panels.map((item: any, idx: number) => {
                  return (
                    <li
                      {...(idx === 0 && {
                        className: 'VerticalTabs__Active--Desktop',
                      })}
                      key={`VerticalTabs__Menu-${idx}`}
                      onClick={() => {
                        goToSlide(idx)
                      }}
                    >
                      {item.title && (
                        <Typography
                          className="VerticalTabs__Label"
                          type="label"
                        >
                          {formatInlineCode(item.title)}
                        </Typography>
                      )}

                      {item.body && (
                        <div className="VerticalTabs__Accordion-Item">
                          <Typography
                            className="VerticalTabs__Item-Body"
                            type="body"
                          >
                            {renderRichText(item.body, options)}
                          </Typography>
                          {item.cta &&
                            item.cta.externalUrl &&
                            item.cta.text && (
                              <Button
                                icon={true}
                                href={item.cta.externalUrl}
                                text={item.cta.text}
                                type="text-link"
                                size="small"
                                theme="light"
                              />
                            )}
                        </div>
                      )}
                    </li>
                  )
                })}
              </ul>
            </Grid>
            <WrapperImage
              className="VerticalTabs__Mac"
              src={Path}
              alt="mac"
              width={690}
              height={345}
              threshold={650}
            />
            <Grid className="VerticalTabs__Content-Container" item xs={8}>
              <div className="VerticalTabs__Content-Outer">
                <WrapperImage
                  className="VerticalTabs__Robot"
                  src={Robot}
                  alt="robot"
                  width={282}
                  height={315}
                  threshold={650}
                />
                <div className="VerticalTabs__Circle" />
                <div className="VerticalTabs__Slider-Nav-Container">
                  <Button
                    type="icon"
                    clickHandler={handleSlidePrev}
                    className="VerticalTabs__Slider-Prev-Button"
                    ariaLabel="Previous slide"
                    arrowOrientation="left"
                    theme={'light'}
                  />

                  <div className="VerticalTabs__Slider-Slide-Position">
                    <Typography type="leadXS">
                      {slideNumber + 1} / {panels.length}
                    </Typography>
                  </div>

                  <Button
                    type="icon"
                    clickHandler={handleSlideNext}
                    className="VerticalTabs__Slider-Next-Button"
                    ariaLabel="Next slide"
                    arrowOrientation="right"
                    theme={'light'}
                  />
                </div>
                <div className="VerticalTabs__Content-Inner">
                  <Slider
                    {...settings}
                    className="VerticalTabs__Slider"
                    ref={(slider: any) => (customSlider.current = slider)}
                  >
                    {panels.map((item: any, idx: number) => {
                      return (
                        <div
                          className="VerticalTabs__Slider-Item"
                          key={`VerticalTabs__Slider-Item-${idx}`}
                        >
                          <div>
                            {item.headline && (
                              <Typography
                                className="VerticalTabs__Item-Header"
                                type="header3"
                              >
                                {item.headline}
                              </Typography>
                            )}
                            {item.body && (
                              <Typography
                                className="VerticalTabs__Item-Body"
                                type="body"
                              >
                                {renderRichText(item.body, options)}
                              </Typography>
                            )}
                            {item.cta && item.cta.text && (
                              <Button
                                icon={true}
                                href={
                                  item.cta.internalLink
                                    ? internalLinkHandler(item.cta.internalLink)
                                    : item.cta.externalUrl
                                }
                                text={item.cta.text}
                                type="text-link"
                                size="small"
                                theme="light"
                              />
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </Slider>
                </div>
              </div>
            </Grid>
          </Grid>
        </GlobalContainer>
      </VerticalTabContainer>
    ) : null
  }
  return null
}

export default VerticalTab
